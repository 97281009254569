<template>
  <v-layout class="d-flex flex-column">
    <v-toolbar class="dodo_toptoolbar" dark color="primary" style="z-index: 10">
      <v-toolbar-items v-show="back_title" class="dodo_toolbar_items">
        <v-btn
          text
          justify-left
          class="justify-left teal darken-2"
          :to="back_action"
        >
          <v-icon left large>keyboard_arrow_left</v-icon>
          <div class="d-flex flex-column align-end mr-5 mt-3">
            <span class="align-self-start"> {{ back_title }} </span>
            <h6
              v-show="back_subtitle != null"
              class="grey--text back-title-sub"
            >
              {{ back_subtitle }}
            </h6>
            <v-chip
              v-if="back_type"
              x-small
              outlined
              class="mt-1 grow-0 shrink-1 align-end"
              color="green"
              ><span>{{ back_type }}</span></v-chip
            >
          </div>
        </v-btn>
        <v-divider vertical></v-divider>
      </v-toolbar-items>
      <v-toolbar-title v-show="title" class="white--text mx-5 py-5 px-2"
        >{{ title }}
      </v-toolbar-title>
      <v-toolbar-title v-show="section_title" class="white--text mx-5 py-5 px-2"
        >{{ section_title }}
      </v-toolbar-title>
      <v-layout
        v-if="selected_view >= 0"
        v-model="selected_view"
        elevation="0"
        style="height: 100%"
      >
        <v-hover
          v-for="view in getViews"
          v-bind:key="view.id"
          v-slot="{ hover }"
          transition="slide-x-transition"
        >
          <v-btn
            transition="slide-x-transition"
            style="
              height: 100%;
              border-radius: 0px;
              border: 0px;
              box-shadow: none;
              z-index: 1001;
            "
            :color="view.color + ' darken-0 elevation-1'"
            class="inline-btn px-6"
            :class="hover ? 'transition-fast-in-fast-out' : ''"
            :style="
              isIntentDetail && view.title == 'Intenções'
                ? 'background-color: #6170C2 !important; opacity: 1'
                : ''
            "
            :to="'/customerService2/' + view.to"
          >
            <v-icon>{{ view.icon }}</v-icon>
            <span
              transition="slide-x-transition"
              class="transition-fast-in-fast-out"
              v-if="hover || selected_view == view.view"
            >
              {{ view.title }}
            </span>
          </v-btn>
        </v-hover>
      </v-layout>
      <v-spacer></v-spacer>
      <slot name="deactivator"> </slot>
      <slot name="intentButtons"> </slot>
      <div v-if="isFilterable" class="mr-2">
        <v-dialog
        width="500"
        v-model="filterDialog"
        @click:outside="restoreFilters()"
        >
          <template v-slot:activator="{ attrs }">
            <v-btn
              :class="getViews[selected_view].color"
              v-on:click="filterDialog = true"
              dark
              v-bind="attrs"
            >
              <v-icon left
                >filter_alt</v-icon
              >
              <span v-on:click.self="filterDialog = true">{{
                numOfFilters > 0 ? numOfFilters : "filtrar"
              }}</span>

              <div
                dark
                class="elevation-0"
                v-if="numOfFilters > 0"
                x-small
                @click.stop="triggerClearFilters()"
              >
                <v-icon right>close</v-icon>
              </div>
            </v-btn>
          </template>

          <v-card class="secondary-background">
            <v-card-title class="text-h5"> Filtros </v-card-title>

            <v-card-text> <slot name="filters"></slot> </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="triggerClearFilters()">
                Limpar filtros
              </v-btn>
              <v-btn color="primary" text @click="triggerFilter()"> Filtrar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div class="mr-5 d-flex" v-if="isSearchable">
        <v-btn class="mr-2" icon @click="openSearch()">
          <v-icon>search</v-icon>
        </v-btn>
        <v-expand-x-transition>
          <v-text-field
            ref="searchInput"
            v-show="togle"
            v-if="isSearchable"
            v-model="search"
            label="Buscar"
            single-line
            hide-details
            clearable
            color="teal lighten-5"
            @input="searchFunction(search)"
          ></v-text-field>
        </v-expand-x-transition>
      </div>
    </v-toolbar>
    <v-layout
      style="z-index: 10"
      class="tab-indicator lighten-1"
      :class="getViews[selected_view].color"
      elevation-24
      v-if="selected_view >= 0"
      clas="indigo"
    >
    </v-layout>
   
  </v-layout>
</template>
<script>

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: [
    "title",
    "back_title",
    "back_subtitle",
    "back_type",
    "selected_view",
    "back_action",
    "isSearchable",
    "searchFunction",
    "isIntentDetail",
    "isEditing",
    "isFilterable",
    "numOfFilters",
    "hasInitiative",
  ],
  components: {
  },

  data: function () {
    return {
      search: "",
      filterDialog: false,
      section_title: null,
      togle: false,
      views: [
        {
          title: "Conversas",
          color: "orange darken-2",
          to: `channel/${this.$route.params.service_id}/conversations/list`,
          view: 0,
          icon: "forum",
        },
        {
          title: "Respostas Rápidas",
          color: "pink",
          to: `${this.$route.params.service_id}/quick_reply/list`,
          view: 1,
          icon: "quickreply",
        },
        {
          title: "Iniciativas",
          color: "purple",
          to: `${this.$route.params.service_id}/initiative/list`,
          view: 2,
          icon: "announcement",
        },
      ],
    };
  },

  mounted() {
    // window.alert(this.currentService)
    // if (!this.$router.app._route.params.service_id) {
    //   this.$router.push('/not_found')
    // }
  },

  computed: {
    ...mapGetters("rasaServices", {
      currentService: "currentService",
    }),
    getViews() {
        return this.views
    },
    loggedUser() {
      if (this.$store.getters.getLoggedUser) {
        return this.$store.getters.getLoggedUser;
      } else {
        return {};
      }
    },

    _selected_view: () => this.props.selected_view,
  },

  methods: {
    openCommentDialog(type) {
      this.$refs.commentDialog.open();
      this.$refs.commentDialog.type = type;
    },

    openFilterDialog() {
      this.$emit("filtersOpen");
      this.filterDialog = true;
    },

    restoreFilters() {
      this.$emit("restoreFilters")
    },

    openSearch() {
      this.togle = !this.togle;
      setTimeout(() => {
        this.$refs.searchInput.$refs.input.focus();
      }, 1);
    },

    reset() {
      Object.assign(this.$data, this.$options.data());
    },

    triggerFilter() {
      this.$emit("filter");
      this.filterDialog = false;
    },

    triggerClearFilters() {
      this.$emit("clearFilters");
      this.filterDialog = false;
    },
  },
  watch: {
    filterDialog(){
      if(this.filterDialog) {
        this.openFilterDialog()
      }
    }
  },
};
</script>
<style lang="scss">
.history-link {
  cursor: pointer;
  text-align: bottom;
  text-decoration: underline;
}
.history-disabled {
  text-align: bottom;
  text-decoration: line-through;
  color: rgb(211, 211, 211);
}
.tab-indicator {
  height: 5px;
}
.back-title-sub {
  font-size: 9px;
  text-decoration: none;
}
.shine {
  ::after {
    content: " ";
    opacity: 0.18 !important;
  }
}
.dodo_toptoolbar {
  .v-toolbar,
  .v-toolbar__content {
    padding: 0px;
    height: 64px;
  }
  .dodo_toolbar_items .v-btn__content {
    justify-content: left;
    text-transform: title;
  }

  .v-btn-toggle .v-btn {
    padding: 10px;
    height: 64px;
  }

  .inline-btn {
    box-shadow: none !important;
    opacity: 1;
    padding: 10px;
    min-width: 130px;
  }
}
</style>